import styled from "styled-components";

import {space} from "@scriba/ui-lib";

const StyledLogoImage = styled.img`
  margin: ${space('md')}px;
  width: calc(100% - ${space('md')}px - ${space('md')}px);
`

const Logo = () => (
  <StyledLogoImage src={process.env.PUBLIC_URL + '/full-logo.png'} />
)


export default Logo;
