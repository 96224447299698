import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import styled from 'styled-components';

import {space, surface, textColor, Button, Title} from "@scriba/ui-lib";

import AutoBreadcrumbs from "./AutoBreadcrumbs";
import {LogoutButton} from "../auth/LogoutButton";


const Container = styled('div')`
  padding: ${space('lg')}px ${space('xl')}px;
  display: flex;
  justify-content: space-between;

  button {
    fill: ${textColor('main')};
    color: ${textColor('main')};
  }
`

const GrowingContainer = styled('div')`
  flex-grow: 1;
`

const BreadcrumbsContainer = styled('div')`
  background-color: ${surface('sheet')};
  padding: ${space('md')}px ${space('xl')}px;
`

const NavigationBar = ({pageTitle, withBackButton}: {pageTitle?: string, withBackButton?: boolean}) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      <Container>
        <GrowingContainer>
          {pageTitle && <Title uppercase level={3} title={pageTitle} />}
          {withBackButton && (
            <Button
              iconName='chevron-left'
              color='secondary'
              onClick={() => history.goBack()}
            />
          )}
        </GrowingContainer>
        <LogoutButton />
      </Container>
      {location.pathname !== "/" && (
        <BreadcrumbsContainer>
          <AutoBreadcrumbs />
        </BreadcrumbsContainer>
      )}
    </>
  )
}

export default NavigationBar;
