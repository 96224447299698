import React, {FunctionComponent} from "react";
import {useQuery} from "@apollo/client";
import styled from "styled-components";
import {useHistory, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

import {space, Fieldset, Sheet, Title, Button, getGridSize} from "@scriba/ui-lib";

import objectives, { useRemoveObjective } from "../queries/objective";
import {
  GetObjectiveQuery,
  GetObjectiveQueryVariables,
  ListAssetsQuery
} from "../generated/graphql";
import {ObjectiveOverviewBar} from "../components/ObjectiveOverviewBar";
import ScenarioCard from "../components/scenarios/ScenarioCard";
import { toast } from "react-toastify";
import {Card} from "@scriba/ui-lib/dist/components/Card";
import Text from "@scriba/ui-lib/dist/components/Text";
import {formatMonetaryAmount} from "../services/format";
import {sortScenarios} from "../services/objectives";
import { monthsBetween, netValue, OtherAssetSubType } from "@scriba/common";
import {useConfirmation} from "../providers/ConfirmDialogProvider";
import assets from "../queries/assets";

const CardsContainer = styled('div')`
  padding-top: ${space('xl')}px;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  column-gap: ${space('xxl')}px;
`

const ScenariosContainer = styled('div')`
  flex: 2;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-rows: 1fr;
  column-gap: ${space('xxl')}px;
  row-gap: ${space('xxl')}px;
`

const LoanCardContainer = styled('div')`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: ${space('xxl')}px;

  > div {
    height: calc(50% - (${space('xxl')}px / 2) - (2* ${getGridSize(9)}));
  }
`

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ObjectiveScenariosOverviewPage: FunctionComponent = () => {
  const {t} = useTranslation('objectives');
  let { objectiveId } = useParams<{objectiveId: string}>();
  const history = useHistory();
  const { data } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objectives.get, {variables: { id: objectiveId }});
  const { data: dataAsset } = useQuery<ListAssetsQuery>(assets.list);
  const confirm = useConfirmation();
  const removeObjective = useRemoveObjective({
    onError: () => {
      toast.error(t('objectives:remove.error.toast'));
    },
    onCompleted: () => {
      toast.success(t('objectives:remove.success.toast'));
      history.push("/")
    },
  });
  if (!data?.objective) return null;
  const objective = data.objective;
  const loanAmount = objective.ownFundsPercentage ? objective.amount*(1-objective.ownFundsPercentage) : undefined;
  const ownFundsFuture = objective.futureAvailableCash ? objective.futureAvailableCash : undefined;
  const sortedScenarios = [...(objective.scenarios ?? [])];

  const acceptedSubTypes = [OtherAssetSubType.bankAccount, OtherAssetSubType.crypto, OtherAssetSubType.stocks];
  const filteredAssets = !dataAsset ? [] : dataAsset.assets.filter(asset => acceptedSubTypes.includes(asset.subType));
  const estimatedFutureOwnFunds = filteredAssets.map(asset => netValue(asset, objective.objectiveDate)).reduce((sum, current) => sum + current, 0);
  const objectiveDelay = objective ? monthsBetween(new Date(), objective.objectiveDate) : 0;
  sortedScenarios.sort(sortScenarios);

  return (
    <Fieldset>
      <TitleBar>
        <Title title={t('scenario.overview.page.title')} level={3} />
        <Button
          iconName={"delete"}
          label={t('common:objectives.card.delete.title')}
          onClick={async (a) => {
            confirm({
              title: t('objectives:remove.confirmation.title', {objectiveName: objective.name}),
              content: t('objectives:remove.confirmation.content')
            }).then(() => {
              removeObjective(objective.id); 
              history.push('/');
            })}}
        />
      </TitleBar>
      <Sheet>
        <ObjectiveOverviewBar objective={objective}  />
        <CardsContainer>
          <ScenariosContainer>
            {sortedScenarios.map(scenario =>
              <ScenarioCard key={scenario.id} scenario={scenario} objective={objective} />
            )}
          </ScenariosContainer>
          <LoanCardContainer>
            {ownFundsFuture ? (
              <Card 
                color={"main"} 
                iconName={"euro"}
                onClick={() => history.push({pathname: `/objectives/${objective.id}/edit`, state: {step: 1}})}
              >
                <Fieldset>
                  <Title title={t('scenario.ownFundsFuture.card.title', {nbMonths: objectiveDelay})} level={4} />
                  <Title title={formatMonetaryAmount(ownFundsFuture, objective.currency.code)} level={1} />

                  <Title title={t('scenario.ownFundsFuture.estimated.card.title', {nbMonths: objectiveDelay})} level={4} />
                  <Title title={formatMonetaryAmount(estimatedFutureOwnFunds, objective.currency.code)} level={1} />
                </Fieldset>
              </Card>
            ) : null}

            {loanAmount !== undefined && loanAmount !== null ? (
              <Card 
                color={"main"} 
                iconName={"account-balance"}
                onClick={() => history.push({pathname: `/objectives/${objective.id}/edit`, state: {step: 2}})}
              >
                <Fieldset>
                  <Title title={t('scenario.loan.card.title')} level={4} />
                  <Title title={formatMonetaryAmount(loanAmount, objective.currency.code)} level={1} />
                  <Text><Trans i18nKey="objectives:scenario.loan.card.content" /></Text>
                </Fieldset>
              </Card>
            ) : null}
          </LoanCardContainer>
        </CardsContainer>
      </Sheet>
    </Fieldset>
  );
}
