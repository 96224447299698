import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import styled from "styled-components";

import { ObjectiveType } from "@scriba/common";
import { Sheet, space, Title } from "@scriba/ui-lib";

import ObjectiveInputForm from "../components/forms/ObjectiveInputForm";
import AmountObjectiveComputationForm from "../components/forms/AmountObjectiveComputationForm";
import {CreateObjectiveMutationMutation, CreateObjectiveMutationMutationVariables, ObjectiveDetailsFragmentFragment} from "../generated/graphql";
import objectives, {fragments} from "../queries/objective";

const ObjSheet = styled(Sheet)`
  margin: ${space('md')}px;
  padding: ${space('md')}px;
`;

const MainDiv = styled.div`
  margin: ${space('md')}px;
`;

const SubDiv = styled.div`
  padding-left: ${space('md')}px;
  display: flex;
`;


export const CreateAmountObjectivePage: FunctionComponent = () => {
  const {t} = useTranslation(['objectives', 'common']);
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const previous = () => setStep(0)
  const [newObj, setNewObj] = useState<ObjectiveDetailsFragmentFragment>();
  const [createObjective] = useMutation<CreateObjectiveMutationMutation, CreateObjectiveMutationMutationVariables>(
    objectives.create,
    {
      onError: (error) => {
        toast.error(t('objectives:new.error.toast'));
      },
      update: async (cache, res) => {
        cache.modify({
          fields: {
            objectives(existingObjectiveRefs = [], { readField }) {
              const newObjectiveRef = cache.writeFragment({
                data: res.data!.createObjective.data,
                fragmentName: "ObjectiveDetailsFragment",
                fragment: fragments.details,
              });
              return [...existingObjectiveRefs, newObjectiveRef];
            }
          }
        });
      },
    }
  )
  const body = (step === 0 || step === 1) ? (
      <ObjectiveInputForm
        objective = {{subType: ObjectiveType.amount}}
        onSubmit={async (input) => {
          if (step === 0) {
            setStep(1);
          } else {
            const objective = {
                ...input,
                taxesPerc: 0,
                subType: ObjectiveType.amount,
                ownFundsPercentage: 1,
                currencyCode: 'EUR' //TODO
            };
            const res = await createObjective({
              variables: {input: objective},
            });
            if(!res.data || !res.data.createObjective.data) {
              toast.error(t('objectives:new.error.toast'));
            } else {
              setNewObj(res.data.createObjective.data);
              setStep(2);
            }
          }
        }}
        step = {step}
        previous = {previous}
      />
    ) : (
    <AmountObjectiveComputationForm objective={newObj!} previous = {previous}/>
  )

  return (
    <MainDiv>
      <SubDiv>
        <Title uppercase iconName={"euro"} level={4} title={t('objectives:create.amount.title')}/>
      </SubDiv>

      <ObjSheet>
        {body}
      </ObjSheet>

    </MainDiv>
  );
}
