import {ApolloCache, gql, useMutation} from "@apollo/client";
import {ApolloError} from "@apollo/client/errors";
import {useCallback} from "react";

import {typify} from "@scriba/common";

import {fragments as assetFragments} from "./assets";
import {
  AllAssetsDetailsFragmentFragment,
  ListMovementsQuery,
  MovementDetailsFragmentFragment,
  RemoveMovementMutation
} from "../generated/graphql";

export const fragments = {
  details: gql`
    fragment MovementDetailsFragment on Movement {
      id
      currency {
        code
      }
      asset {
        id
        name
      }
      amount
      startDate
      nbOccurrence
      period
      endDate
    }
  `,
}

const queries = {
  list: gql`
    query ListMovements {
      movements {
        ...MovementDetailsFragment
      }
    }
    ${fragments.details}
  `,
  get: gql`
    query GetMovement($id: ID!) {
      movement(id: $id) {
        ...MovementDetailsFragment
      }
    }
    ${fragments.details}
  `,
  create: gql`
    mutation CreateMovement($input: MovementCreate!) {
      createMovement(input: $input) {
        data {
          ...MovementDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  update: gql`
    mutation UpdateMovement($input: MovementUpdate!) {
      updateMovement(input: $input) {
        data {
          ...MovementDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
  remove: gql`
    mutation RemoveMovement($id: ID!) {
      removeMovement(id: $id) {
        data {
          ...MovementDetailsFragment
        }
        error
        status
      }
    }
    ${fragments.details}
  `,
}

export default queries;

export const removeMovementFromList = <T>(cache: ApolloCache<T>, filter: (movement: MovementDetailsFragmentFragment) => boolean) => {
  try {
    const data = cache.readQuery<ListMovementsQuery>({query: queries.list});
    cache.writeQuery({
      query: queries.list,
      data: data && {
        movements: data.movements.filter(filter),
      },
    });
  } catch (err) {
    console.error({err})
  }
};

export function useRemoveMovement({onError, onCompleted}:{onError: (error: ApolloError) => void, onCompleted: (data: RemoveMovementMutation) => void}) {
  const [ removeMovement ] = useMutation(queries.remove, {
    onError,
    update: (cache, res) => {
      if (res?.data?.removeMovement?.data) {
        const objToRemove = res.data.removeMovement.data;
        removeMovementFromList(cache, a => a.id !== objToRemove.id);

        //Remove from asset's movements
        const assetCacheId = cache.identify(typify(objToRemove.asset))
        try {
          const data = cache.readFragment<AllAssetsDetailsFragmentFragment>({
            id: assetCacheId,
            fragmentName: "AllAssetsDetailsFragment",
            fragment: assetFragments.details,
          });
          cache.writeFragment({
            id: assetCacheId,
            fragment: assetFragments.details,
            fragmentName: "AllAssetsDetailsFragment",
            data: data && {
              ...data,
              movements: data.movements.filter(a => a.id !== objToRemove.id),
            },
          });
        } catch (err) {
          console.error({err})
        }
      }
    },
    onCompleted,
  });
  return useCallback((id) => {
    return removeMovement({
      variables: {id},
    });
  }, [removeMovement]);
}
