import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import { ObjectiveType } from "@scriba/common";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import styled from "styled-components";

import { Sheet, space, Title } from "@scriba/ui-lib";

import ObjectiveInputForm from "../components/forms/ObjectiveInputForm";
import BuildingObjectiveComputationForm from "../components/forms/BuildingObjectiveComputationForm";
import {CreateObjectiveMutationMutation, CreateObjectiveMutationMutationVariables, ObjectiveCreate, ObjectiveDetailsFragmentFragment, ObjectiveUpdate, UpdateObjectiveMutation, UpdateObjectiveMutationVariables} from "../generated/graphql";
import objectives from "../queries/objective";
import { useHistory } from "react-router-dom";

const ObjSheet = styled(Sheet)`
  margin: ${space('md')}px;
  padding: ${space('md')}px;
`;

const MainDiv = styled.div`
  margin: ${space('md')}px;
`;

const SubDiv = styled.div`
  padding-left: ${space('md')}px;
  display: flex;
`;

export const CreateBuildingObjectivePage: FunctionComponent = () => {
  const {t} = useTranslation(['objectives', 'common']);
  const history = useHistory();
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const previous = (step: 0 | 1 | 2, objective?: ObjectiveDetailsFragmentFragment) => {
    if (!!objective?.id) {
      history.push({
        pathname: `/objectives/${objective.id}/edit`,
        state: {step}
      });
    } else {
      setStep(step);
    }
  };
  const [newObj, setNewObj] = useState<ObjectiveDetailsFragmentFragment>();
  const [createObjective] = useMutation<CreateObjectiveMutationMutation, CreateObjectiveMutationMutationVariables>(
    objectives.create,
    {
      onError: (error) => {
        toast.error(t('assets:objectives.error.toast'));
      },
    },
  );
  const [updateObjective] = useMutation<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>(
    objectives.update,
    {
      onError: (error) => {
        toast.error(t('objectives:new.error.toast'));
      },
    }
  );

  const processCreation = async (request: {variables: {input: ObjectiveCreate}}) => {
    const res = await createObjective(request);

    if(!res || !res.data || !res.data.createObjective.data) {
      toast.error(t('objectives:new.error.toast'));
    } else {
      if (res.data.createObjective.data.id)
        setNewObj(res.data.createObjective.data);
      setStep(2);
    }
  };

  const processUpdate = async (request: {variables: {input: ObjectiveUpdate}}) => {
    const res = await updateObjective(request);

    if(!res || !res.data || !res.data.updateObjective.data) {
      toast.error(t('objectives:new.error.toast'));
    } else {
      if ( res.data.updateObjective.data.id)
        setNewObj(res.data.updateObjective.data);
      setStep(2);
    }
  }

  const body = (step === 0 || step === 1) ? (
    <ObjectiveInputForm
      objective = {newObj || {subType: ObjectiveType.building}}
      onSubmit={async (input) => {
        if (step === 0) {
          setStep(1);
        } else {
          const objective = {
            ...input,
            subType: ObjectiveType.building,
            ownFundsPercentage: 0.2,
            currencyCode: 'EUR', //TODO
          };
          const request = {variables: {input: objective}};
          if (objective.id === undefined) {
            await processCreation(request);
          } else {
            await processUpdate(request);
          }
        }
      }}
      step = {step}
      previous = {previous}
    />
  ) : (
    <BuildingObjectiveComputationForm objective={newObj!} previous={previous}/>
  )

  return (
    <MainDiv>
      <SubDiv>
        <Title iconName={"home"} level={4} title={t('objectives:create.building.title')}>
        </Title>
      </SubDiv>

      <ObjSheet>
        {body}
      </ObjSheet>

    </MainDiv>
  );
}
