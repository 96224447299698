import React, {FunctionComponent, useState} from "react";
import {useForm, useFieldArray} from "react-hook-form";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";

import {Button, InputRow, Fieldset, Sheet, SwitchGroup, TableFooter, Text, Title} from "@scriba/ui-lib";
import {grossValue, Loan, OtherAssetSubType} from "@scriba/common";

import {FormInput} from "./types";
import TextInputField from "../fields/TextInputField";
import SelectInputField from "../fields/SelectInputField";
import MonetaryAmountInputField from "../fields/MonetaryAmountInputField";
import DatePickerField from "../fields/DatePickerField";
import PercentageInputField from "../fields/PercentageInputField";

import {space} from "@scriba/ui-lib/dist/themes/accessors";

import loans, {useCreateLoan, useRemoveLoan} from "../../queries/loans";
import {
  OtherAssetDetailsFragmentFragment,
  UpdateLoanMutation,
  UpdateLoanMutationVariables
} from "../../generated/graphql";
import Group from "../Group";
import {ComputingAsset} from "@scriba/common/dist/models/assets";

type OtherAssetInputs = {
  name: string;
  subType: OtherAssetSubType;
  currentValue?: number | null;
  acquisitionValue?: number | null;
  acquisitionDate: Date;
  evolutionRate?: number | null;
  sharePercentage?: number | null;
  currencyCode: string;
  loans?: Loan[]
};

interface OtherAssetInputFormProps extends FormInput<OtherAssetInputs> {
  asset?: Partial<OtherAssetDetailsFragmentFragment>
}

const LoanTitleLine = styled('div')`
  display: flex;
  align-items: center;
`;

const LoanGroup = styled(Group)`
  margin-top: ${space('md')}px;
`

const BottomButton = styled('div')`
  margin-top: ${space('md')}px;
`

const LoansGroup = styled('div')`
  margin-top: ${space('lg')}px;
`

const OtherAssetInputFrom: FunctionComponent<OtherAssetInputFormProps> = (props) => {
  const {onSubmit, asset} = props;
  const {t} = useTranslation(['assets', 'common', 'form']);
  const formMethods = useForm<OtherAssetInputs>({
    defaultValues: {
      ...asset,
      ...(asset?.id ? {currentValue: grossValue(asset as ComputingAsset)} : {}),
    }
  });
  const defaultLoan = {
    amount: null,
    yearlyRate: null,
    monthlyAmount: null,
    subscriptionDate: null,
    sharePercentage: 1,
  };
  const {handleSubmit, control, formState} = formMethods;
  const { dirtyFields } = formState;
  const { fields: loanFields, append: appendLoan, remove: removeLoanFromFields } = useFieldArray({
    keyName: "fieldId",
    control,
    name: "loans"
  });
  const [hasLoans, setHasLoans] = useState(loanFields && loanFields.length > 0);
  const removeLoanFromGql = useRemoveLoan ({
      onError: () => {
        toast.error(t('assets:loan.remove.error.toast'));
      },
      onCompleted: () => {
        toast.success(t('assets:loan.remove.success.toast'));
      }
    }
  )

  const createLoan = useCreateLoan({
    onError: (err) => {
      console.error(err);
      toast.error(t('assets:loan.new.error.toast'));
    },
  });
  const [updateLoan] = useMutation<UpdateLoanMutation, UpdateLoanMutationVariables>(
    loans.update,
    {
      onError: (err) => {
        console.error(err);
        toast.error(t('assets:loan.update.error.toast'));
      },
    }
  )
  const currency = {code: 'EUR', rateToEuro: 1}; //TODO to replace with a picker when we handle multiple currencies

  const updateLoansBeforeSubmit = async ({loans, ...asset}: OtherAssetInputs) => {
    const dirtyLoans = dirtyFields.loans;
    const assetId = props.asset?.id
    const isLoanDirty = (idx: number) => !dirtyLoans ? false : Object.values(dirtyLoans[idx] || {}).includes(true)
    const isLoanNew = (idx: number) => !loanFields[idx].id;

    if(!!assetId) {
      // Asset is not new, we should update/create loans there
      await Promise.all(loans?.map(async(loan, idx) => {
        const isNew = isLoanNew(idx);
        const isDirty = isLoanDirty(idx);
        if(isNew) {
          await createLoan({...loan, assetId});
        } else if (isDirty) {
          await updateLoan({variables: {input: {...loan, id: loanFields[idx].id!}}})
        }
      }) || [Promise.resolve()])
    }
    return onSubmit({
      ...asset,
      currencyCode: currency.code,
      ...(hasLoans ? {loans} : {}),
    });
  }

  return (
    <Sheet>
      <Title level={4} title={t('assets:other.form.title')} />
      <form onSubmit={handleSubmit(updateLoansBeforeSubmit)}>
        <Fieldset>

          <TextInputField
            label={t('assets:field.name.label')}
            name="name"
            formMethods={formMethods}
            required={true}
          />

          <SelectInputField
            label={t('assets:field.subtype.label')}
            name="subType"
            formMethods={formMethods}
            required={true}
            options={Object.keys(OtherAssetSubType).map(subType => (
              <option key={subType} value={subType}>{t(`assets:other.subType.${subType}`)}</option>
            ))}
          />

          <MonetaryAmountInputField
            currency={currency}
            label={t('assets:field.acquisitionValue.label')}
            name="acquisitionValue"
            formMethods={formMethods}
            registerOptions={{
              validate: (val) => {
                const currentValue = formMethods.getValues('currentValue');
                if (currentValue === undefined ||  currentValue === null) {
                  return val || t('assets:field.current.acquisition.value.required')
                }
                return undefined;
              }
            }}
            required={false}
          />

          <MonetaryAmountInputField
            currency={currency}
            label={t('assets:field.currentValue.label')}
            name="currentValue"
            formMethods={formMethods}
            registerOptions={{
              validate: (val) => {
                const acquisitionValue = formMethods.getValues('acquisitionValue');
                if (acquisitionValue === undefined ||  acquisitionValue === null) {
                  return val || t('assets:field.current.acquisition.value.required')
                }
                return undefined;
              }
            }}
            required={false}
          />

          <DatePickerField
            label={t('assets:field.acquisitionDate.label')}
            name="acquisitionDate"
            formMethods={formMethods}
            required={false}
          />

          <PercentageInputField
            label={t('assets:field.evolutionRate.label')}
            name="evolutionRate"
            tooltip={t('assets:field.evolutionRate.tooltip')}
            formMethods={formMethods}
            required={false}
          />

          <PercentageInputField
            label={t('assets:field.sharePercentage.label')}
            name="sharePercentage"
            tooltip={t('assets:field.sharePercentage.tooltip')}
            formMethods={formMethods}
            required={false}
          />

          <InputRow
            label={t('assets:field.loan.switch.label')}
            required={false}
            renderInput={() => (
              <SwitchGroup>
                <Button
                  label={t('common:switch.label.yes')}
                  appearance={hasLoans ? 'solid' : 'transparent'}
                  onClick={() => {
                    setHasLoans(true);
                    if(!loanFields || loanFields.length === 0) {
                      appendLoan(defaultLoan);
                    }
                  }}
                />
                <Button
                  label={t('common:switch.label.no')}
                  appearance={hasLoans ? 'transparent' : 'solid'}
                  onClick={() => setHasLoans(false)}
                />
              </SwitchGroup>
            )}
          />
        </Fieldset>

        {hasLoans && (
          <LoansGroup>
          {loanFields.map((loanField, idx) => {
            return (
              <LoanGroup key={loanField.fieldId}>
                <Fieldset>
                  <LoanTitleLine>
                    <Title title={t('assets:field.loan.title', {idx: idx+1})} level={5} />
                    <Button appearance='transparent' iconName='delete' color='main' size="md" onClick={async () => {
                      if (loanFields.length === 1) { setHasLoans(false) }
                      if (!!loanField.id) {
                        await removeLoanFromGql(loanField.id);
                      }
                      removeLoanFromFields(idx)
                    }} />
                  </LoanTitleLine>

                  <MonetaryAmountInputField
                    currency={currency}
                    label={t('assets:field.loan.amount')}
                    name={`loans[${idx}].amount`}
                    required={true}
                    formMethods={formMethods}
                    defaultValue={loanField.amount}
                  />

                  <PercentageInputField
                    label={t('assets:field.loan.yearlyRate')}
                    name={`loans[${idx}].yearlyRate`}
                    required={true}
                    formMethods={formMethods}
                    defaultValue={loanField.yearlyRate}
                  />

                  <MonetaryAmountInputField
                    currency={currency}
                    label={t('assets:field.loan.monthlyAmount')}
                    name={`loans[${idx}].monthlyAmount`}
                    required={true}
                    formMethods={formMethods}
                    defaultValue={loanField.monthlyAmount}
                  />

                  <DatePickerField
                    label={t('assets:field.loan.subscriptionDate')}
                    name={`loans[${idx}].subscriptionDate`}
                    required={true}
                    formMethods={formMethods}
                    defaultValue={loanField.subscriptionDate}
                  />

                  <PercentageInputField
                    label={t('assets:field.loan.sharePercentage')}
                    name={`loans[${idx}].sharePercentage`}
                    required={true}
                    formMethods={formMethods}
                    defaultValue={loanField.sharePercentage}
                  />

                  {idx+1 === loanFields.length ? (
                    <TableFooter onClick={() => appendLoan(defaultLoan)}>
                      <Button
                        iconName='add'
                        color='secondary'
                      />
                      <Text color='neutral'>{t('assets:new.loan.button.label')}</Text>
                    </TableFooter>
                  ) : null}
                </Fieldset>
              </LoanGroup>
            )
          })}
          </LoansGroup>
        )}

        <BottomButton>
          <Button type="submit" label={t('form:button.submit.label')} />
        </BottomButton>
      </form>
    </Sheet>
  );

}

export default OtherAssetInputFrom;
