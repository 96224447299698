import i18n, {InitOptions} from "i18next";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';

import {i18nCache, i18nDebug, i18nVersion} from "./config";

const i18nOptions:InitOptions = {
  lng: "fr",
  fallbackLng: "fr",
  ns: ['common', 'auth', 'form', 'navigation'],
  defaultNS: 'common',
  keySeparator: false,
  debug: i18nDebug,
  backend: {
    backends: [
      ...(i18nCache ? [LocalStorageBackend] : [] ),
      HttpApi
    ],
    backendOptions: [
      ...(i18nCache ? [{ defaultVersion: i18nVersion}] : []),
      { loadPath: '/locales/{{lng}}/{{ns}}.json' }
    ]
  },
  interpolation: {
    escapeValue: false // react already safes from xss
  },
}

registerLocale('fr', fr)

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nOptions)
  .then(async (_t) => {
    await i18n.loadNamespaces(['assets']);
  });


export default i18n;
