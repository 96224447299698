import React, {FunctionComponent} from 'react';
import {useHistory} from 'react-router-dom';
import styled from "styled-components";

import {space} from "@scriba/ui-lib";

import SignUpForm from "../components/forms/SignUpForm";

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: ${space('xxl')}px;
  margin-right: ${space('xxl')}px;
  padding-right: ${space('xxl')}px;
`

const LeftBox = styled.div`
  flex: 3;
`

const RightBox = styled.div`
  flex: 1;
`

export const SignUpPage:FunctionComponent = () => {
  const history = useHistory();

  return (
    <Container>
      <LeftBox />
      <RightBox>
        <SignUpForm onSignUp={async () => history.replace('/')} />
      </RightBox>
    </Container>
  )
}
