import {FunctionComponent} from 'react';

import {Title} from "@scriba/ui-lib";

import {useTranslation} from "react-i18next";
import {AssetsList} from "../components/AssetsList";
import { MovementsList } from '../components/MovementsList';

export const AssetsListPage: FunctionComponent = () => {
  const {t} = useTranslation(['assets', 'movements', 'common']);
  return (
    <>
      <Title uppercase level={2} title={t('assets:other.list.title')} />
      <AssetsList/>
      <Title uppercase level={2} title={t('movements:other.list.title')} />
      <MovementsList/>
    </>
  )
}
