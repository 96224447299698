import React from 'react';
import DatePicker from 'react-datepicker';
import {FieldValues} from "react-hook-form";
import i18n from 'i18next';
import dayjs from "dayjs";
import styled from "styled-components";

import {Input} from "@scriba/ui-lib";

import {InputFieldProps} from "./index";
import ControlledInputField from "./ControlledInputField";

import "react-datepicker/dist/react-datepicker.css";

const WideInput = styled(Input)`
  width: 100%;
`

const DatePickerField = function<T extends FieldValues, V>({registerOptions, ...props}: InputFieldProps<T, V>) {
  return (
    <ControlledInputField
      render={({ onChange, value }) => {
        return (
          <DatePicker
            dateFormat={"dd/MM/yyyy"}
            selected={value && dayjs(value).toDate()}
            onChange={onChange}
            locale={i18n.language}
            customInput={<WideInput />}
          />
        )
      }}
      {...props}
      registerOptions={{...registerOptions, setValueAs: (v) => v && dayjs(v).format('YYYY-MM-DD')}}
    />
  )
}

export default DatePickerField;
