import React, {FunctionComponent} from "react";
import {useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

import {MovementPeriod} from "@scriba/common";
import {Table, TableFooter, TBody, Td, Th, THead, Tr, Text, Button} from "@scriba/ui-lib";

import movements, {useRemoveMovement} from "../queries/movements";
import ButtonLink from "./navigation/ButtonLink";
import {formatDate, formatMonetaryAmount} from "../services/format";
import {useConfirmation} from "../providers/ConfirmDialogProvider";
import {ListMovementsQuery} from "../generated/graphql";

export const MovementsList: FunctionComponent = () => {
  const {t} = useTranslation(['movements', 'common']);
  const history = useHistory();
  const { data } = useQuery<ListMovementsQuery>(movements.list);
  const confirm = useConfirmation();
  const removeMovement = useRemoveMovement({
    onError: () => {
      toast.error(t('movements:remove.error.toast'));
    },
    onCompleted: () => {
      toast.success(t('movements:remove.success.toast'));
    },
  });
  return (
    <>
      {!!data && (
        <Table style={{tableLayout: "fixed"}}>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col style={{width: "150px"}}/>
            <col style={{width: "100px"}}/>
          </colgroup>
          <THead>
            <Tr>
              <Th>{t('movements:table.header.asset')}</Th>
              <Th>{t('movements:table.header.amount')}</Th>
              <Th>{t('movements:table.header.startDate')}</Th>
              <Th>{t('movements:table.header.recurrence')}</Th>
              <Th>{t('movements:table.header.endDate')}</Th>
              <Th colSpan={2}/>
            </Tr>
          </THead>
          <TBody>
            {data.movements.map(movement => (
              <Tr key={movement.id}>
                <Td>{movement.asset.name}</Td>
                <Td>{formatMonetaryAmount(movement.amount, movement.currency.code)}</Td>
                <Td>{formatDate(movement.startDate)}</Td>
                <Td>{(movement.period !== MovementPeriod.once ? (movement.nbOccurrence + 'x / ') : "") + t(`movements:other.period.${movement.period}`)}</Td>
                <Td>{movement.endDate ? formatDate(movement.endDate) : '-'}</Td>
                <Td>
                  <ButtonLink
                    appearance='transparent'
                    label={t('common:button.modify.label')}
                    to={`/movements/${movement.id}/edit`}
                  />
                </Td>
                <Td>
                  <Button
                    appearance='transparent'
                    iconName='delete'
                    color='main'
                    onClick={() => {
                      confirm({
                        title: t('movements:remove.confirmation.title'),
                        content: t('movements:remove.confirmation.content')
                      }).then(() =>
                        removeMovement(movement.id)
                      );
                    }}
                  />
                </Td>
              </Tr>
            ))}
            <Tr>
              <td colSpan={7} onClick={() => history.push('/movements/new')}>
                <TableFooter>
                  <Button
                    iconName='add'
                    color='secondary'
                  />
                  <Text color='neutral'>{t('movements:new.other.button.label')}</Text>
                </TableFooter>
              </td>
            </Tr>
          </TBody>
        </Table>
      )}
    </>
  );
}


