"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fixFirefoxFlexScroll = exports.getGridSize = exports.sizeAccessor = void 0;
var theme_accessor_1 = require("../theme-accessor");
var sizeAccessor = function (keys) {
    if (keys === void 0) { keys = []; }
    return function (size) { return theme_accessor_1.themeAccessor(__spreadArrays(keys, [size])); };
};
exports.sizeAccessor = sizeAccessor;
var getGridSize = function (multiplier) {
    if (multiplier === void 0) { multiplier = 1; }
    return function (props) { return multiplier * props.theme.gridSize + 'px'; };
};
exports.getGridSize = getGridSize;
var fixFirefoxFlexScroll = function () { return 'min-height: 0;'; };
exports.fixFirefoxFlexScroll = fixFirefoxFlexScroll;
