import {useTranslation} from "react-i18next";

import {Button, Card, Title} from "@scriba/ui-lib";

import React from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddObjectiveCard = () => {
  const {t} = useTranslation('objectives');
  const history = useHistory();

  return (
    <Card onClick={() => history.push("/objectives/new")}>
      <Container>
        <div>
          <Button size={'md'} iconName={"add"} iconSize={"xl"}/>
        </div>
        <p/>
        <div>
          <Title title={t('common:objectives.list.page.link.label')} level={2}/>
        </div>
      </Container>
    </Card>
  )
}

export default AddObjectiveCard;
