import React from 'react';
import styled from 'styled-components';
import {Link, useLocation} from "react-router-dom";

import {color, fontSize, getGridSize, space, surface} from "@scriba/ui-lib";
import ButtonLink from "./ButtonLink";

import Logo from "../Logo";
import Text from "@scriba/ui-lib/dist/components/Text";
import {useTranslation} from "react-i18next";
import {borderRadius} from "@scriba/ui-lib/dist/themes/accessors";

const StyledSideNav = styled('div')`
    position: fixed;
    height: 100%;
    width: ${getGridSize(20)};
    background-color: ${surface('sheet')};
`;

const NavBlock = styled('ul')`
  list-style: none;
  padding-inline-start: ${space('lg')}px;
  display: grid;
  row-gap: ${space('md')}px;
`

const BaseNavigationItem = styled('li')`
  display: flex;
  justify-content: space-between;
  width: ${getGridSize(18)}
`

const ClickableNavigationItem = styled(BaseNavigationItem)<{current:boolean}>`
  a {
    text-decoration: none;
    ${p => p.current ? 'font-weight: bold;' : ''}
    width: ${getGridSize(16)}
  }

  &:hover::after {
    content: "";
    width: ${space('sm')}px;
    position: relative;
    background: ${color('main', 'base')};
    border-radius: ${borderRadius('xxl')}px 0 0 ${borderRadius('xxl')}px;
  }
`

const SideBarButtonLink = styled(ButtonLink)`
  font-size: ${fontSize("label")}px;
  text-align: left;
  margin-left: -${space('sm')}px;
  padding-left: ${space('sm')}px;
  width: 100%;
`

const NavigationItem = ({path, label, button} : {path: string, label: string, button?: boolean}) => {
  const location = useLocation();
  const {t} = useTranslation('navigation');

  return (
    <ClickableNavigationItem current={location.pathname === path}>
      {button ? (
        <SideBarButtonLink to={path} label={t(label)} iconName="add" size="sm"/>
      ) : (
        <Link to={path}>
          <Text color="contrast">{t(label)}</Text>
        </Link>
      )}
    </ClickableNavigationItem>
  )
}

const SideBar = () => {
  return (
    <StyledSideNav>
      <Link to="/">
        <Logo/>
      </Link>
      <NavBlock>
        <NavigationItem path="/" label="navbar.dashboard.label" />
        <NavigationItem path="/assets" label="navbar.assets.label" />
        <NavigationItem path="/assets/other/new" label="navbar.assets.create.label" button/>
        <NavigationItem path="/objectives" label="navbar.objective.list.label" />
        <NavigationItem path="/objectives/new" label="navbar.objective.create.label" button/>
        <NavigationItem path="/movements" label="navbar.movements.list.label" />
        <NavigationItem path="/movements/new" label="navbar.movements.create.label" button/>
        <NavigationItem path="/borrowingCapacity" label="navbar.borrowingCapacity.label"/>
      </NavBlock>
    </StyledSideNav>
  )
}

export default SideBar;
