import React, {FunctionComponent} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import Group from "../Group";

import {Button, Fieldset, InputRow, Title,} from '@scriba/ui-lib';
import {netValue, OtherAssetSubType} from "@scriba/common";

import {FormInput} from "./types";
import TextInputField from "../fields/TextInputField";
import DatePickerField from "../fields/DatePickerField";
import MonetaryAmountInputField from "../fields/MonetaryAmountInputField";
import {formatMonetaryAmount} from "../../services/format";
import assets from "../../queries/assets";
import {Currency, ListAssetsQuery, ObjectiveDetailsFragmentFragment, ObjectiveType} from "../../generated/graphql";
import {sumAssetValues} from "../../services/helpers";

import FieldsAndButton from "../FieldsAndButton";
import {HelpTooltip} from "@scriba/ui-lib/dist/components/HelpTooltip";
import PercentageInputField from "../fields/PercentageInputField";

interface ObjectiveInputFormProps extends FormInput<ObjectiveDetailsFragmentFragment> {
  objective: Partial<ObjectiveDetailsFragmentFragment> & Pick<ObjectiveDetailsFragmentFragment, 'subType'>
  step: 0 | 1;
  previous: Function;
}


const ObjectiveInputFrom: FunctionComponent<ObjectiveInputFormProps> = ({onSubmit, objective, step, previous}) => {
  const {t} = useTranslation(['objectives', 'common', 'form']);
  const formMethods = useForm<ObjectiveDetailsFragmentFragment>({
    defaultValues: {
      ...objective
    },
    shouldUnregister: false,
  });
  const watchDate = formMethods.watch('objectiveDate');
  const {handleSubmit} = formMethods;
  const currency: Currency = {code: 'EUR', rateToEuro: 1} //TODO fetch user's default currency

  const filterBeforeSubmit = ({...objective}: ObjectiveDetailsFragmentFragment) =>
    onSubmit({
      ...objective
    })
  const monthDiff = (d1: Date, d2: Date) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
  }
  const { data } = useQuery<ListAssetsQuery>(assets.list);
  const acceptedSubTypes = [OtherAssetSubType.bankAccount, OtherAssetSubType.crypto, OtherAssetSubType.stocks];
  const filteredAssets = !data ? [] : data.assets.filter(asset => acceptedSubTypes.includes(asset.subType));
  const futureOwnFunds = data && watchDate ? filteredAssets.map(asset => netValue(asset, new Date(watchDate))).reduce((sum, current) => sum + current, 0) : 0;
  const values = sumAssetValues(filteredAssets, currency)

  const getFields = () => {
    switch(step) {
      case 0:
        return (
          <Fieldset>
            <TextInputField
              label={t('objectives:field.name.label')}
              name="name"
              formMethods={formMethods}
              required={true}
            />

            <MonetaryAmountInputField
              currency={currency}
              label={t('objectives:field.amount.label')}
              name="amount"
              formMethods={formMethods}
              required={true}
              defaultValue={objective?.amount || null}
              tooltip={t('objectives:field.amount.tooltip')}
            />

            <div>
              <DatePickerField
                label={t('objectives:field.date.label')}
                name="objectiveDate"
                formMethods={formMethods}
                required={true}
              />
              <InputRow
                label={""}
                required={false}
                renderInput={() => (
                  <>
                    {t('objectives:field.dateIn.label', {nbMonths : watchDate ? monthDiff(new Date(), new Date(watchDate)) : "0"})}
                  </>
                )}
              />
            </div>

            { objective.subType === ObjectiveType.building ? (
              <PercentageInputField
                name="taxesPerc"
                label={t('objectives:field.taxes.label')}
                tooltip={t('objectives:field.taxes.tooltip')}
                formMethods={formMethods}
                required={true}
                defaultValue={objective?.amount || 0.15}
              />
              ) : <></>
            }
           </Fieldset>
        );
      case 1:
        return (
          <Fieldset>

          <div>
            <MonetaryAmountInputField
              currency={currency}
              label={t('objectives:field.ownFundsAmount.label')}
              name="availableCash"
              formMethods={formMethods}
              tooltip={t('objectives:field.ownFundsAmount.tooltip')}
              required={true}
              defaultValue={objective?.amount || null}
            />
            <InputRow
              label={""}
              required={false}
              renderInput={() => (
                <>
                  <div>
                    {t('objectives:result.netBalance.label')}
                    {formatMonetaryAmount(values, currency.code)}
                    <HelpTooltip text={t('objectives:result.netBalance.tooltip')} />
                  </div>
                </>
            )}/>
          </div>

          <div>
            <MonetaryAmountInputField
              currency={currency}
              label={t('objectives:field.futureOwnFundsAmount.label', {nbMonths: monthDiff(new Date(), new Date(watchDate))})}
              name="futureAvailableCash"
              formMethods={formMethods}
              tooltip={t('objectives:field.futureOwnFundsAmount.tooltip')}
              required={true}
              defaultValue={objective?.amount || null}
            />
            <InputRow
              label={""}
              required={false}
              renderInput={() => (
                <>
                  {t('objectives:result.futureNetBalance.label')}
                  {formatMonetaryAmount(futureOwnFunds, currency.code)}
                </>
              )}
            />
          </div>

          </Fieldset>
        );
    }
  }


  const bottomButton = (
        <InputRow
          label={t('common:other.requiredFields')}
          required={false}
          renderInput={() => (
            <Button type="submit" label={t(`objectives:button.inputForm.step${step}.label`)}/>
          )}
        />
  );

  return (
    <Group>
      {step === 1 && (
        <div>
          <Button
            iconName='chevron-left'
            color='secondary'
            onClick={() => previous(0, undefined)}
          />
        </div>
      )}
      <Title level={4}  title={t('objectives:start.form.title')} />

      <form onSubmit={handleSubmit(filterBeforeSubmit)}>
        <FieldsAndButton fields={getFields()} button={bottomButton}/>
      </form>
    </Group>
  );

}

export default ObjectiveInputFrom;
