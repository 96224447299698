import React, {FunctionComponent} from "react";
import styled from "styled-components";

import {space} from "@scriba/ui-lib";
import {ListObjectivesQuery} from "../generated/graphql";

import MyObjectiveCard from "../components/MyObjectiveCard"
import AddObjectiveCard from "../components/AddObjectiveCard"
import { useQuery } from "@apollo/client";
import objectives from "../queries/objective";

const ScenariosContainer = styled('div')`
  padding-top: ${space('xl')}px;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
  row-gap: ${space('xl')}px;
`

export const MyObjectivesList: FunctionComponent = () => {
  const { data } = useQuery<ListObjectivesQuery>(objectives.list, {fetchPolicy: "cache-and-network"});
  return (
    <ScenariosContainer>
        {!!data && data.objectives.map(objective =>
            <MyObjectiveCard key={objective.id} objective={objective} />
        )}
        <AddObjectiveCard/>
    </ScenariosContainer>
  );
}


