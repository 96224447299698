import React, {FunctionComponent} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import styled from "styled-components";

import {space} from "@scriba/ui-lib";
import Logo from "../components/Logo";

import LoginForm from "../components/forms/LoginForm";

type LoginState = {
  from?: string;
}

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: ${space('xxl')}px;
  margin-right: ${space('xxl')}px;
  padding-right: ${space('xxl')}px;
`

const LogoContainer = styled.div`
  display: flex;
  width: 50%;
  padding-left: 30%;
`

const BigBox = styled.div`
  flex: 3;
`

const SmallBox = styled.div`
  flex: 1;
  padding-right: 20%;
`

export const LoginPage:FunctionComponent = () => {
  const { state } = useLocation<LoginState>();
  const [ redirect, setRedirect ] = React.useState(false)

  if (redirect) {
    return <Redirect to={state?.from || '/'} />
  }

  return (
    <Container>
      <BigBox>
        <LogoContainer>
          <Logo/>
        </LogoContainer>
      </BigBox>
      <SmallBox>
        <LoginForm onLogin={async () => setRedirect(true)} />
      </SmallBox>
    </Container>
  )
}
