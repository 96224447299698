export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};



export type Entity = {
  id: Scalars['ID'];
};

export type User = Entity & {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  assets?: Maybe<Array<Maybe<Asset>>>;
  maxDebtRatio?: Maybe<Scalars['Float']>;
};

export type UserUpdate = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  maxDebtRatio?: Maybe<Scalars['Float']>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['ID'];
  rateToEuro: Scalars['Float'];
};

export type Asset = {
  id: Scalars['ID'];
  user: User;
  name: Scalars['String'];
  currency: Currency;
  currentValue?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
  acquisitionValue?: Maybe<Scalars['Float']>;
  acquisitionDate: Scalars['Date'];
  loans?: Maybe<Array<Maybe<Loan>>>;
  movements?: Maybe<Array<Maybe<Movement>>>;
};

export enum OtherAssetSubType {
  realty = 'realty',
  bankAccount = 'bankAccount',
  stocks = 'stocks',
  crypto = 'crypto',
  financialInsurance = 'financialInsurance',
  artwork = 'artwork',
  pension = 'pension',
  professionalAssets = 'professionalAssets',
  collectionVehicle = 'collectionVehicle',
  other = 'other'
}

export type OtherAsset = Entity & Asset & {
  __typename?: 'OtherAsset';
  id: Scalars['ID'];
  user: User;
  name: Scalars['String'];
  currency: Currency;
  currentValue?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
  acquisitionValue?: Maybe<Scalars['Float']>;
  acquisitionDate: Scalars['Date'];
  loans: Array<Loan>;
  movements: Array<Movement>;
  subType: OtherAssetSubType;
  evolutionRate: Scalars['Float'];
  sharePercentage: Scalars['Float'];
};

export enum ScenarioType {
  askForMoney = 'askForMoney',
  buyRealEstate = 'buyRealEstate',
  buyStocks = 'buyStocks',
  saveMore = 'saveMore'
}

export type Scenario = {
  id: Scalars['ID'];
  objective: Objective;
  scenarioType: ScenarioType;
  amount?: Maybe<Scalars['Float']>;
};

export type AskForMoneyScenario = Scenario & Entity & {
  __typename?: 'AskForMoneyScenario';
  id: Scalars['ID'];
  objective: Objective;
  scenarioType: ScenarioType;
  amount?: Maybe<Scalars['Float']>;
  donationAmount?: Maybe<Scalars['Float']>;
  loanAmount?: Maybe<Scalars['Float']>;
  payOffDate?: Maybe<Scalars['Date']>;
  interestRate?: Maybe<Scalars['Float']>;
};

export type BuyRealEstateScenario = Scenario & Entity & {
  __typename?: 'BuyRealEstateScenario';
  id: Scalars['ID'];
  objective: Objective;
  scenarioType: ScenarioType;
  amount?: Maybe<Scalars['Float']>;
  investedOwnFunds?: Maybe<Scalars['Float']>;
  ownFundsPerc?: Maybe<Scalars['Float']>;
  taxesPerc?: Maybe<Scalars['Float']>;
  rentingRentability?: Maybe<Scalars['Float']>;
  occupationRate?: Maybe<Scalars['Float']>;
  loanDuration?: Maybe<Scalars['Float']>;
  interestRate?: Maybe<Scalars['Float']>;
  precompte?: Maybe<Scalars['Float']>;
  agencyFees?: Maybe<Scalars['Float']>;
  upkeep?: Maybe<Scalars['Float']>;
  otherFees?: Maybe<Scalars['Float']>;
  evolutionRate?: Maybe<Scalars['Float']>;
  sellTaxes?: Maybe<Scalars['Float']>;
  agencySellFees?: Maybe<Scalars['Float']>;
};

export type BuyStocksScenario = Scenario & Entity & {
  __typename?: 'BuyStocksScenario';
  id: Scalars['ID'];
  objective: Objective;
  scenarioType: ScenarioType;
  amount?: Maybe<Scalars['Float']>;
  rentabilityChoice?: Maybe<Scalars['Float']>;
  investedAmount?: Maybe<Scalars['Float']>;
  stockIndex?: Maybe<Scalars['String']>;
};

export type SaveMoreScenario = Scenario & Entity & {
  __typename?: 'SaveMoreScenario';
  id: Scalars['ID'];
  objective: Objective;
  scenarioType: ScenarioType;
  amount?: Maybe<Scalars['Float']>;
};

export type ScenarioUpdate = {
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  investedOwnFunds?: Maybe<Scalars['Float']>;
  ownFundsPerc?: Maybe<Scalars['Float']>;
  taxesPerc?: Maybe<Scalars['Float']>;
  rentingRentability?: Maybe<Scalars['Float']>;
  occupationRate?: Maybe<Scalars['Float']>;
  loanDuration?: Maybe<Scalars['Float']>;
  precompte?: Maybe<Scalars['Float']>;
  agencyFees?: Maybe<Scalars['Float']>;
  upkeep?: Maybe<Scalars['Float']>;
  otherFees?: Maybe<Scalars['Float']>;
  evolutionRate?: Maybe<Scalars['Float']>;
  rentabilityChoice?: Maybe<Scalars['Float']>;
  sellTaxes?: Maybe<Scalars['Float']>;
  agencySellFees?: Maybe<Scalars['Float']>;
  loanAmount?: Maybe<Scalars['Float']>;
  donationAmount?: Maybe<Scalars['Float']>;
  payOffDate?: Maybe<Scalars['Date']>;
  interestRate?: Maybe<Scalars['Float']>;
  investedAmount?: Maybe<Scalars['Float']>;
  stockIndex?: Maybe<Scalars['String']>;
};

export type ScenarioResponse = Response & {
  __typename?: 'ScenarioResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Scenario>;
};

export type BuyRealEstateScenarioResponse = Response & {
  __typename?: 'BuyRealEstateScenarioResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<BuyRealEstateScenario>;
};

export type OtherAssetCreate = {
  name: Scalars['String'];
  subType: OtherAssetSubType;
  currencyCode: Scalars['String'];
  currentValue?: Maybe<Scalars['Float']>;
  acquisitionValue?: Maybe<Scalars['Float']>;
  evolutionRate?: Maybe<Scalars['Float']>;
  rentabilityChoice?: Maybe<Scalars['Float']>;
  sharePercentage?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
  acquisitionDate?: Maybe<Scalars['Date']>;
};

export type OtherAssetUpdate = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  subType?: Maybe<OtherAssetSubType>;
  currencyCode?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Float']>;
  acquisitionValue?: Maybe<Scalars['Float']>;
  evolutionRate?: Maybe<Scalars['Float']>;
  sharePercentage?: Maybe<Scalars['Float']>;
  valueDate?: Maybe<Scalars['Date']>;
  acquisitionDate?: Maybe<Scalars['Date']>;
};

export enum ResponseStatus {
  ok = 'ok',
  error = 'error'
}

export type Response = {
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Entity>;
};

export type UserResponse = Response & {
  __typename?: 'UserResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<User>;
};

export type OtherAssetResponse = Response & {
  __typename?: 'OtherAssetResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<OtherAsset>;
};

export type Loan = Entity & {
  __typename?: 'Loan';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  currency?: Maybe<Currency>;
  yearlyRate: Scalars['Float'];
  monthlyAmount: Scalars['Float'];
  subscriptionDate: Scalars['Date'];
  sharePercentage: Scalars['Float'];
  asset: Asset;
};

export type LoanCreate = {
  currencyCode?: Maybe<Scalars['String']>;
  assetId: Scalars['ID'];
  amount: Scalars['Float'];
  yearlyRate: Scalars['Float'];
  monthlyAmount: Scalars['Float'];
  subscriptionDate: Scalars['Date'];
  sharePercentage: Scalars['Float'];
};

export type LoanUpdate = {
  id: Scalars['ID'];
  currencyCode?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  yearlyRate?: Maybe<Scalars['Float']>;
  monthlyAmount?: Maybe<Scalars['Float']>;
  subscriptionDate?: Maybe<Scalars['Date']>;
  sharePercentage?: Maybe<Scalars['Float']>;
};

export type LoanResponse = Response & {
  __typename?: 'LoanResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Loan>;
};

export enum MovementPeriod {
  week = 'week',
  month = 'month',
  year = 'year',
  once = 'once'
}

export type Movement = Entity & {
  __typename?: 'Movement';
  id: Scalars['ID'];
  currency: Currency;
  amount: Scalars['Float'];
  startDate: Scalars['Date'];
  nbOccurrence: Scalars['Int'];
  period: MovementPeriod;
  endDate?: Maybe<Scalars['Date']>;
  asset: Asset;
};

export type MovementCreate = {
  assetId: Scalars['ID'];
  currencyCode: Scalars['String'];
  amount: Scalars['Float'];
  startDate: Scalars['Date'];
  nbOccurrence: Scalars['Int'];
  period: MovementPeriod;
  endDate?: Maybe<Scalars['Date']>;
};

export type MovementUpdate = {
  id: Scalars['ID'];
  currencyCode?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  nbOccurrence: Scalars['Int'];
  period: MovementPeriod;
  endDate?: Maybe<Scalars['Date']>;
};

export type MovementResponse = Response & {
  __typename?: 'MovementResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Movement>;
};

export enum ObjectiveType {
  building = 'building',
  amount = 'amount'
}

export type Objective = Entity & {
  __typename?: 'Objective';
  id: Scalars['ID'];
  subType: ObjectiveType;
  name: Scalars['String'];
  amount: Scalars['Float'];
  objectiveDate: Scalars['Date'];
  availableCash: Scalars['Float'];
  futureAvailableCash: Scalars['Float'];
  ownFundsPercentage: Scalars['Float'];
  taxesPerc: Scalars['Float'];
  scenarios?: Maybe<Array<Scenario>>;
  currency: Currency;
};

export type ObjectiveCreate = {
  subType: ObjectiveType;
  name: Scalars['String'];
  amount: Scalars['Float'];
  objectiveDate: Scalars['Date'];
  availableCash: Scalars['Float'];
  futureAvailableCash: Scalars['Float'];
  ownFundsPercentage: Scalars['Float'];
  taxesPerc: Scalars['Float'];
  currencyCode: Scalars['String'];
};

export type ObjectiveUpdate = {
  id: Scalars['ID'];
  subType?: Maybe<ObjectiveType>;
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  objectiveDate?: Maybe<Scalars['Date']>;
  availableCash?: Maybe<Scalars['Float']>;
  futureAvailableCash?: Maybe<Scalars['Float']>;
  ownFundsPercentage?: Maybe<Scalars['Float']>;
  taxesPerc?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type ObjectiveResponse = Response & {
  __typename?: 'ObjectiveResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Objective>;
};

export type RecoverPasswordToken = Entity & {
  __typename?: 'RecoverPasswordToken';
  id: Scalars['ID'];
  user: User;
  email: Scalars['String'];
  expirationDate: Scalars['DateTime'];
  consumptionDate?: Maybe<Scalars['DateTime']>;
};

export type RecoverPasswordTokenResponse = Response & {
  __typename?: 'RecoverPasswordTokenResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<RecoverPasswordToken>;
};

export enum IncomeType {
  professional = 'professional',
  rent = 'rent',
  financial = 'financial',
  allowances = 'allowances',
  other = 'other'
}

export enum IncomePeriodicity {
  month = 'month',
  year = 'year'
}

export type Income = Entity & {
  __typename?: 'Income';
  id: Scalars['ID'];
  user: User;
  incomeType: IncomeType;
  amount: Scalars['Float'];
  currency: Currency;
  periodicity: IncomePeriodicity;
};

export type IncomeCreate = {
  incomeType: IncomeType;
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
  periodicity: IncomePeriodicity;
};

export type IncomeUpdate = {
  id: Scalars['ID'];
  incomeType?: Maybe<IncomeType>;
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  periodicity?: Maybe<IncomePeriodicity>;
};

export type IncomeResponse = Response & {
  __typename?: 'IncomeResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Income>;
};

export enum ExpenseType {
  housing = 'housing',
  loan = 'loan',
  other = 'other'
}

export enum ExpensePeriodicity {
  month = 'month',
  year = 'year'
}

export type Expense = Entity & {
  __typename?: 'Expense';
  id: Scalars['ID'];
  user: User;
  expenseType: ExpenseType;
  amount: Scalars['Float'];
  currency: Currency;
  periodicity: ExpensePeriodicity;
};

export type ExpenseCreate = {
  expenseType: ExpenseType;
  amount: Scalars['Float'];
  currencyCode: Scalars['String'];
  periodicity: ExpensePeriodicity;
};

export type ExpenseUpdate = {
  id: Scalars['ID'];
  expenseType?: Maybe<ExpenseType>;
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  periodicity?: Maybe<ExpensePeriodicity>;
};

export type ExpenseResponse = Response & {
  __typename?: 'ExpenseResponse';
  status: ResponseStatus;
  error?: Maybe<Scalars['String']>;
  data?: Maybe<Expense>;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  recoverPasswordToken?: Maybe<RecoverPasswordToken>;
  currencies: Array<Currency>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  loans: Array<Loan>;
  movement?: Maybe<Movement>;
  movements: Array<Movement>;
  objective?: Maybe<Objective>;
  objectives: Array<Objective>;
  scenario?: Maybe<Scenario>;
  incomes: Array<Income>;
  expenses: Array<Expense>;
};


export type QueryRecoverPasswordTokenArgs = {
  id: Scalars['ID'];
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetsArgs = {
  withDeleted?: Maybe<Scalars['Boolean']>;
};


export type QueryLoansArgs = {
  withDeleted?: Maybe<Scalars['Boolean']>;
};


export type QueryMovementArgs = {
  id: Scalars['ID'];
};


export type QueryMovementsArgs = {
  withDeleted?: Maybe<Scalars['Boolean']>;
};


export type QueryObjectiveArgs = {
  id: Scalars['ID'];
};


export type QueryScenarioArgs = {
  id: Scalars['ID'];
};


export type QueryIncomesArgs = {
  withDeleted?: Maybe<Scalars['Boolean']>;
};


export type QueryExpensesArgs = {
  withDeleted?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: UserResponse;
  logout: Scalars['Boolean'];
  signup: UserResponse;
  updateUser: UserResponse;
  initiateRecoverPassword: RecoverPasswordTokenResponse;
  changePassword: UserResponse;
  createOtherAsset: OtherAssetResponse;
  updateOtherAsset: OtherAssetResponse;
  removeOtherAsset: OtherAssetResponse;
  createLoan: LoanResponse;
  updateLoan: LoanResponse;
  removeLoan: LoanResponse;
  createMovement: MovementResponse;
  updateMovement: MovementResponse;
  removeMovement: MovementResponse;
  createObjective: ObjectiveResponse;
  updateObjective: ObjectiveResponse;
  removeObjective: ObjectiveResponse;
  updateScenario: ScenarioResponse;
  createExpense: ExpenseResponse;
  updateExpense: ExpenseResponse;
  removeExpense: ExpenseResponse;
  createIncome: IncomeResponse;
  updateIncome: IncomeResponse;
  removeIncome: IncomeResponse;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignupArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserUpdate;
};


export type MutationInitiateRecoverPasswordArgs = {
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  tokenId: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationCreateOtherAssetArgs = {
  input: OtherAssetCreate;
};


export type MutationUpdateOtherAssetArgs = {
  input: OtherAssetUpdate;
};


export type MutationRemoveOtherAssetArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLoanArgs = {
  input: LoanCreate;
};


export type MutationUpdateLoanArgs = {
  input: LoanUpdate;
};


export type MutationRemoveLoanArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMovementArgs = {
  input: MovementCreate;
};


export type MutationUpdateMovementArgs = {
  input: MovementUpdate;
};


export type MutationRemoveMovementArgs = {
  id: Scalars['ID'];
};


export type MutationCreateObjectiveArgs = {
  input: ObjectiveCreate;
};


export type MutationUpdateObjectiveArgs = {
  input: ObjectiveUpdate;
};


export type MutationRemoveObjectiveArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateScenarioArgs = {
  input: ScenarioUpdate;
};


export type MutationCreateExpenseArgs = {
  input: ExpenseCreate;
};


export type MutationUpdateExpenseArgs = {
  input: ExpenseUpdate;
};


export type MutationRemoveExpenseArgs = {
  id: Scalars['ID'];
};


export type MutationCreateIncomeArgs = {
  input: IncomeCreate;
};


export type MutationUpdateIncomeArgs = {
  input: IncomeUpdate;
};


export type MutationRemoveIncomeArgs = {
  id: Scalars['ID'];
};

export type AllAssetsDetailsFragmentFragment = (
  { __typename?: 'OtherAsset' }
  & OtherAssetDetailsFragmentFragment
);

export type GetAssetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAssetQuery = (
  { __typename?: 'Query' }
  & { asset?: Maybe<(
    { __typename?: 'OtherAsset' }
    & OtherAssetDetailsFragmentFragment
  )> }
);

export type ListAssetsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAssetsQuery = (
  { __typename?: 'Query' }
  & { assets: Array<(
    { __typename?: 'OtherAsset' }
    & OtherAssetDetailsFragmentFragment
  )> }
);

export type OtherAssetDetailsFragmentFragment = (
  { __typename?: 'OtherAsset' }
  & Pick<OtherAsset, 'id' | 'name' | 'subType' | 'acquisitionValue' | 'currentValue' | 'acquisitionDate' | 'valueDate' | 'evolutionRate' | 'sharePercentage'>
  & { currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'rateToEuro'>
  ), loans: Array<(
    { __typename?: 'Loan' }
    & LoanDetailsFragmentFragment
  )>, movements: Array<(
    { __typename?: 'Movement' }
    & MovementDetailsFragmentFragment
  )> }
);

export type CreateOtherAssetMutationVariables = Exact<{
  input: OtherAssetCreate;
}>;


export type CreateOtherAssetMutation = (
  { __typename?: 'Mutation' }
  & { createOtherAsset: (
    { __typename?: 'OtherAssetResponse' }
    & Pick<OtherAssetResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'OtherAsset' }
      & OtherAssetDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateOtherAssetMutationVariables = Exact<{
  input: OtherAssetUpdate;
}>;


export type UpdateOtherAssetMutation = (
  { __typename?: 'Mutation' }
  & { updateOtherAsset: (
    { __typename?: 'OtherAssetResponse' }
    & Pick<OtherAssetResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'OtherAsset' }
      & OtherAssetDetailsFragmentFragment
    )> }
  ) }
);

export type RemoveOtherAssetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveOtherAssetMutation = (
  { __typename?: 'Mutation' }
  & { removeOtherAsset: (
    { __typename?: 'OtherAssetResponse' }
    & Pick<OtherAssetResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'OtherAsset' }
      & OtherAssetDetailsFragmentFragment
    )> }
  ) }
);

export type ExpenseDetailsFragmentFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'id' | 'expenseType' | 'amount' | 'periodicity'>
  & { currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'rateToEuro'>
  ) }
);

export type ListExpensesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListExpensesQuery = (
  { __typename?: 'Query' }
  & { expenses: Array<(
    { __typename?: 'Expense' }
    & ExpenseDetailsFragmentFragment
  )> }
);

export type CreateExpenseMutationMutationVariables = Exact<{
  input: ExpenseCreate;
}>;


export type CreateExpenseMutationMutation = (
  { __typename?: 'Mutation' }
  & { createExpense: (
    { __typename?: 'ExpenseResponse' }
    & Pick<ExpenseResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Expense' }
      & ExpenseDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateExpenseMutationVariables = Exact<{
  input: ExpenseUpdate;
}>;


export type UpdateExpenseMutation = (
  { __typename?: 'Mutation' }
  & { updateExpense: (
    { __typename?: 'ExpenseResponse' }
    & Pick<ExpenseResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Expense' }
      & ExpenseDetailsFragmentFragment
    )> }
  ) }
);

export type RemoveExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveExpenseMutation = (
  { __typename?: 'Mutation' }
  & { removeExpense: (
    { __typename?: 'ExpenseResponse' }
    & Pick<ExpenseResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Expense' }
      & ExpenseDetailsFragmentFragment
    )> }
  ) }
);

export type IncomeDetailsFragmentFragment = (
  { __typename?: 'Income' }
  & Pick<Income, 'id' | 'incomeType' | 'amount' | 'periodicity'>
  & { currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'rateToEuro'>
  ) }
);

export type ListIncomesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListIncomesQuery = (
  { __typename?: 'Query' }
  & { incomes: Array<(
    { __typename?: 'Income' }
    & IncomeDetailsFragmentFragment
  )> }
);

export type CreateIncomeMutationMutationVariables = Exact<{
  input: IncomeCreate;
}>;


export type CreateIncomeMutationMutation = (
  { __typename?: 'Mutation' }
  & { createIncome: (
    { __typename?: 'IncomeResponse' }
    & Pick<IncomeResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Income' }
      & IncomeDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateIncomeMutationVariables = Exact<{
  input: IncomeUpdate;
}>;


export type UpdateIncomeMutation = (
  { __typename?: 'Mutation' }
  & { updateIncome: (
    { __typename?: 'IncomeResponse' }
    & Pick<IncomeResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Income' }
      & IncomeDetailsFragmentFragment
    )> }
  ) }
);

export type RemoveIncomeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveIncomeMutation = (
  { __typename?: 'Mutation' }
  & { removeIncome: (
    { __typename?: 'IncomeResponse' }
    & Pick<IncomeResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Income' }
      & IncomeDetailsFragmentFragment
    )> }
  ) }
);

export type LoanDetailsFragmentFragment = (
  { __typename?: 'Loan' }
  & Pick<Loan, 'id' | 'amount' | 'yearlyRate' | 'monthlyAmount' | 'subscriptionDate' | 'sharePercentage'>
  & { currency?: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'rateToEuro'>
  )>, asset: (
    { __typename?: 'OtherAsset' }
    & Pick<OtherAsset, 'id' | 'name'>
  ) }
);

export type ListLoansQueryVariables = Exact<{ [key: string]: never; }>;


export type ListLoansQuery = (
  { __typename?: 'Query' }
  & { loans: Array<(
    { __typename?: 'Loan' }
    & LoanDetailsFragmentFragment
  )> }
);

export type RemoveLoanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveLoanMutation = (
  { __typename?: 'Mutation' }
  & { removeLoan: (
    { __typename?: 'LoanResponse' }
    & Pick<LoanResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Loan' }
      & LoanDetailsFragmentFragment
    )> }
  ) }
);

export type CreateLoanMutationVariables = Exact<{
  input: LoanCreate;
}>;


export type CreateLoanMutation = (
  { __typename?: 'Mutation' }
  & { createLoan: (
    { __typename?: 'LoanResponse' }
    & Pick<LoanResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Loan' }
      & LoanDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateLoanMutationVariables = Exact<{
  input: LoanUpdate;
}>;


export type UpdateLoanMutation = (
  { __typename?: 'Mutation' }
  & { updateLoan: (
    { __typename?: 'LoanResponse' }
    & Pick<LoanResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Loan' }
      & LoanDetailsFragmentFragment
    )> }
  ) }
);

export type MovementDetailsFragmentFragment = (
  { __typename?: 'Movement' }
  & Pick<Movement, 'id' | 'amount' | 'startDate' | 'nbOccurrence' | 'period' | 'endDate'>
  & { currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'code'>
  ), asset: (
    { __typename?: 'OtherAsset' }
    & Pick<OtherAsset, 'id' | 'name'>
  ) }
);

export type ListMovementsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListMovementsQuery = (
  { __typename?: 'Query' }
  & { movements: Array<(
    { __typename?: 'Movement' }
    & MovementDetailsFragmentFragment
  )> }
);

export type GetMovementQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMovementQuery = (
  { __typename?: 'Query' }
  & { movement?: Maybe<(
    { __typename?: 'Movement' }
    & MovementDetailsFragmentFragment
  )> }
);

export type CreateMovementMutationVariables = Exact<{
  input: MovementCreate;
}>;


export type CreateMovementMutation = (
  { __typename?: 'Mutation' }
  & { createMovement: (
    { __typename?: 'MovementResponse' }
    & Pick<MovementResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Movement' }
      & MovementDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateMovementMutationVariables = Exact<{
  input: MovementUpdate;
}>;


export type UpdateMovementMutation = (
  { __typename?: 'Mutation' }
  & { updateMovement: (
    { __typename?: 'MovementResponse' }
    & Pick<MovementResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Movement' }
      & MovementDetailsFragmentFragment
    )> }
  ) }
);

export type RemoveMovementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMovementMutation = (
  { __typename?: 'Mutation' }
  & { removeMovement: (
    { __typename?: 'MovementResponse' }
    & Pick<MovementResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Movement' }
      & MovementDetailsFragmentFragment
    )> }
  ) }
);

export type ObjectiveDetailsFragmentFragment = (
  { __typename?: 'Objective' }
  & Pick<Objective, 'id' | 'name' | 'subType' | 'amount' | 'objectiveDate' | 'availableCash' | 'futureAvailableCash' | 'ownFundsPercentage' | 'taxesPerc'>
  & { scenarios?: Maybe<Array<(
    { __typename?: 'AskForMoneyScenario' }
    & ScenarioDetailsFragment_AskForMoneyScenario_Fragment
  ) | (
    { __typename?: 'BuyRealEstateScenario' }
    & ScenarioDetailsFragment_BuyRealEstateScenario_Fragment
  ) | (
    { __typename?: 'BuyStocksScenario' }
    & ScenarioDetailsFragment_BuyStocksScenario_Fragment
  ) | (
    { __typename?: 'SaveMoreScenario' }
    & ScenarioDetailsFragment_SaveMoreScenario_Fragment
  )>>, currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'rateToEuro'>
  ) }
);

export type ListObjectivesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListObjectivesQuery = (
  { __typename?: 'Query' }
  & { objectives: Array<(
    { __typename?: 'Objective' }
    & ObjectiveDetailsFragmentFragment
  )> }
);

export type GetObjectiveQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetObjectiveQuery = (
  { __typename?: 'Query' }
  & { objective?: Maybe<(
    { __typename?: 'Objective' }
    & ObjectiveDetailsFragmentFragment
  )> }
);

export type CreateObjectiveMutationMutationVariables = Exact<{
  input: ObjectiveCreate;
}>;


export type CreateObjectiveMutationMutation = (
  { __typename?: 'Mutation' }
  & { createObjective: (
    { __typename?: 'ObjectiveResponse' }
    & Pick<ObjectiveResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Objective' }
      & ObjectiveDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateObjectiveMutationVariables = Exact<{
  input: ObjectiveUpdate;
}>;


export type UpdateObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { updateObjective: (
    { __typename?: 'ObjectiveResponse' }
    & Pick<ObjectiveResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Objective' }
      & ObjectiveDetailsFragmentFragment
    )> }
  ) }
);

export type RemoveObjectiveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveObjectiveMutation = (
  { __typename?: 'Mutation' }
  & { removeObjective: (
    { __typename?: 'ObjectiveResponse' }
    & Pick<ObjectiveResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'Objective' }
      & ObjectiveDetailsFragmentFragment
    )> }
  ) }
);

type ScenarioDetailsFragment_AskForMoneyScenario_Fragment = (
  { __typename?: 'AskForMoneyScenario' }
  & Pick<AskForMoneyScenario, 'id' | 'amount' | 'scenarioType'>
  & { objective: (
    { __typename?: 'Objective' }
    & Pick<Objective, 'id'>
  ) }
  & AskForMoneyScenarioFragmentFragment
);

type ScenarioDetailsFragment_BuyRealEstateScenario_Fragment = (
  { __typename?: 'BuyRealEstateScenario' }
  & Pick<BuyRealEstateScenario, 'id' | 'amount' | 'scenarioType'>
  & { objective: (
    { __typename?: 'Objective' }
    & Pick<Objective, 'id'>
  ) }
  & BuyRealEstateScenarioFragmentFragment
);

type ScenarioDetailsFragment_BuyStocksScenario_Fragment = (
  { __typename?: 'BuyStocksScenario' }
  & Pick<BuyStocksScenario, 'id' | 'amount' | 'scenarioType'>
  & { objective: (
    { __typename?: 'Objective' }
    & Pick<Objective, 'id'>
  ) }
  & BuyStocksScenarioFragmentFragment
);

type ScenarioDetailsFragment_SaveMoreScenario_Fragment = (
  { __typename?: 'SaveMoreScenario' }
  & Pick<SaveMoreScenario, 'id' | 'amount' | 'scenarioType'>
  & { objective: (
    { __typename?: 'Objective' }
    & Pick<Objective, 'id'>
  ) }
);

export type ScenarioDetailsFragmentFragment = ScenarioDetailsFragment_AskForMoneyScenario_Fragment | ScenarioDetailsFragment_BuyRealEstateScenario_Fragment | ScenarioDetailsFragment_BuyStocksScenario_Fragment | ScenarioDetailsFragment_SaveMoreScenario_Fragment;

export type BuyRealEstateScenarioFragmentFragment = (
  { __typename?: 'BuyRealEstateScenario' }
  & Pick<BuyRealEstateScenario, 'investedOwnFunds' | 'ownFundsPerc' | 'taxesPerc' | 'rentingRentability' | 'occupationRate' | 'loanDuration' | 'interestRate' | 'precompte' | 'agencyFees' | 'upkeep' | 'otherFees' | 'evolutionRate' | 'sellTaxes' | 'agencySellFees'>
);

export type BuyStocksScenarioFragmentFragment = (
  { __typename?: 'BuyStocksScenario' }
  & Pick<BuyStocksScenario, 'rentabilityChoice' | 'investedAmount' | 'stockIndex'>
);

export type AskForMoneyScenarioFragmentFragment = (
  { __typename?: 'AskForMoneyScenario' }
  & Pick<AskForMoneyScenario, 'loanAmount' | 'donationAmount' | 'payOffDate' | 'interestRate'>
);

export type GetScenarioQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetScenarioQuery = (
  { __typename?: 'Query' }
  & { scenario?: Maybe<(
    { __typename?: 'AskForMoneyScenario' }
    & ScenarioDetailsFragment_AskForMoneyScenario_Fragment
  ) | (
    { __typename?: 'BuyRealEstateScenario' }
    & ScenarioDetailsFragment_BuyRealEstateScenario_Fragment
  ) | (
    { __typename?: 'BuyStocksScenario' }
    & ScenarioDetailsFragment_BuyStocksScenario_Fragment
  ) | (
    { __typename?: 'SaveMoreScenario' }
    & ScenarioDetailsFragment_SaveMoreScenario_Fragment
  )> }
);

export type UpdateScenarioMutationVariables = Exact<{
  input: ScenarioUpdate;
}>;


export type UpdateScenarioMutation = (
  { __typename?: 'Mutation' }
  & { updateScenario: (
    { __typename?: 'ScenarioResponse' }
    & Pick<ScenarioResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'AskForMoneyScenario' }
      & ScenarioDetailsFragment_AskForMoneyScenario_Fragment
    ) | (
      { __typename?: 'BuyRealEstateScenario' }
      & ScenarioDetailsFragment_BuyRealEstateScenario_Fragment
    ) | (
      { __typename?: 'BuyStocksScenario' }
      & ScenarioDetailsFragment_BuyStocksScenario_Fragment
    ) | (
      { __typename?: 'SaveMoreScenario' }
      & ScenarioDetailsFragment_SaveMoreScenario_Fragment
    )> }
  ) }
);

export type UserDetailsFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'maxDebtRatio'>
);

export type RecoverPasswordTokenFragmentFragment = (
  { __typename?: 'RecoverPasswordToken' }
  & Pick<RecoverPasswordToken, 'id' | 'email' | 'expirationDate' | 'consumptionDate'>
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserDetailsFragmentFragment
  )> }
);

export type RecoverPasswordTokenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecoverPasswordTokenQuery = (
  { __typename?: 'Query' }
  & { recoverPasswordToken?: Maybe<(
    { __typename?: 'RecoverPasswordToken' }
    & RecoverPasswordTokenFragmentFragment
  )> }
);

export type SignUpMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & UserDetailsFragmentFragment
    )> }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'error' | 'status'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & UserDetailsFragmentFragment
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type InitiateRecoverPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type InitiateRecoverPasswordMutation = (
  { __typename?: 'Mutation' }
  & { initiateRecoverPassword: (
    { __typename?: 'RecoverPasswordTokenResponse' }
    & Pick<RecoverPasswordTokenResponse, 'error'>
    & { data?: Maybe<(
      { __typename?: 'RecoverPasswordToken' }
      & RecoverPasswordTokenFragmentFragment
    )> }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  tokenId: Scalars['ID'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'error'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & UserDetailsFragmentFragment
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserUpdate;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserResponse' }
    & Pick<UserResponse, 'error'>
    & { data?: Maybe<(
      { __typename?: 'User' }
      & UserDetailsFragmentFragment
    )> }
  ) }
);


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Entity": [
      "User",
      "OtherAsset",
      "AskForMoneyScenario",
      "BuyRealEstateScenario",
      "BuyStocksScenario",
      "SaveMoreScenario",
      "Loan",
      "Movement",
      "Objective",
      "RecoverPasswordToken",
      "Income",
      "Expense"
    ],
    "Asset": [
      "OtherAsset"
    ],
    "Scenario": [
      "AskForMoneyScenario",
      "BuyRealEstateScenario",
      "BuyStocksScenario",
      "SaveMoreScenario"
    ],
    "Response": [
      "ScenarioResponse",
      "BuyRealEstateScenarioResponse",
      "UserResponse",
      "OtherAssetResponse",
      "LoanResponse",
      "MovementResponse",
      "ObjectiveResponse",
      "RecoverPasswordTokenResponse",
      "IncomeResponse",
      "ExpenseResponse"
    ]
  }
};
      export default result;
    