"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtherAssetSubType = void 0;
var OtherAssetSubType;
(function (OtherAssetSubType) {
    OtherAssetSubType["artwork"] = "artwork";
    OtherAssetSubType["bankAccount"] = "bankAccount";
    OtherAssetSubType["collectionVehicle"] = "collectionVehicle";
    OtherAssetSubType["crypto"] = "crypto";
    OtherAssetSubType["financialInsurance"] = "financialInsurance";
    OtherAssetSubType["pension"] = "pension";
    OtherAssetSubType["professionalAssets"] = "professionalAssets";
    OtherAssetSubType["realty"] = "realty";
    OtherAssetSubType["stocks"] = "stocks";
    OtherAssetSubType["other"] = "other";
})(OtherAssetSubType = exports.OtherAssetSubType || (exports.OtherAssetSubType = {}));
