import {FunctionComponent} from 'react';

import {space, Title} from "@scriba/ui-lib";

import {useTranslation} from "react-i18next";
import {CollapsedAssetsList} from "../components/CollapsedAssetsList";
import {MyObjectivesList} from "../components/MyObjectivesList";
import Group from "../components/Group"
import ButtonLink from '../components/navigation/ButtonLink';
import styled from 'styled-components';
import {ValueCard} from "@scriba/ui-lib/dist/components/ValueCard";
import {useQuery} from "@apollo/client";
import {
  Currency,
  ListAssetsQuery} from "../generated/graphql";
import assets from "../queries/assets";
import {formatMonetaryAmount} from "../services/format";
import {totalNetValue, totalGrossValue} from "@scriba/common/dist/services/computations";


const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${space('xl')}px;
  margin-bottom: ${space('md')}px;
`;

const KpiLine = styled('div')`
  display: grid;
  grid-auto-flow: column;
  column-gap: ${space('xxl')}px;
`

export const Home: FunctionComponent = () => {
  const {t} = useTranslation( ['common', 'home']);
  const { data: assetsData } = useQuery<ListAssetsQuery>(assets.list);
  const currency: Currency = {code: 'EUR', rateToEuro: 1} //TODO fetch user's default currency
  const totalNetValueCurrent = assetsData ? totalNetValue(assetsData.assets, currency) : undefined;
  const totalActifsCurrent = assetsData ? totalGrossValue(assetsData.assets, currency, new Date(), true) : undefined;
  const totalPassifsCurrent = totalNetValueCurrent !== undefined && totalActifsCurrent !== undefined ? totalActifsCurrent - totalNetValueCurrent : undefined; 

  return (
    <>
      <KpiLine>
        {totalNetValueCurrent !== undefined && totalActifsCurrent !== undefined && totalPassifsCurrent !== undefined && (
        <>
          <ValueCard
            label={t('home:kpi.total.net.value.label')}
            background={'secondary'}
            backgroundStyle={'solid'}
          >
            <Title
              color="main"
              level={2}
              title={formatMonetaryAmount(totalNetValueCurrent, currency.code)}
            />
          </ValueCard>
          <ValueCard
            label={t('home:kpi.actifs.net.value.label')}
            background={'secondary'}
            backgroundStyle={'solid'}
          >
            <Title
              color="main"
              level={2}
              title={formatMonetaryAmount(totalActifsCurrent, currency.code)}
            />
          </ValueCard>
          <ValueCard
            label={t('home:kpi.passifs.net.value.label')}
            background={'secondary'}
            backgroundStyle={'solid'}
          >
            <Title
              color="main"
              level={2}
              title={formatMonetaryAmount(totalPassifsCurrent, currency.code)}
            />
          </ValueCard>
        </>
        )}
      </KpiLine>
      <TitleBar>
        <Title uppercase level={2} title={t('assets.list.page.link.label')} />
        <ButtonLink
          to={`/assets`}
          label={t('common:assets.list.page.link.label')}
        />
      </TitleBar>
      <Group>
        <CollapsedAssetsList/>
      </Group>
      <TitleBar>
        <Title uppercase level={2} title={t('navigation:navbar.objective.list.label')} />
      </TitleBar>
      <MyObjectivesList/>
    </>
  )
}
