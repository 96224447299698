import React, {FunctionComponent} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import {OtherAsset} from "@scriba/common";
import {Title} from '@scriba/ui-lib';

import OtherAssetInputForm from "../components/forms/OtherAssetInputForm";
import {useCreateOtherAsset} from "../queries/assets/others";
import {useCreateLoan} from "../queries/loans";

export const CreateOtherAssetPage: FunctionComponent = () => {
  const {t} = useTranslation(['assets', 'common']);
  const history = useHistory();
  const createOtherAsset = useCreateOtherAsset({
    onError: (err) => {
      console.error(err);
      toast.error(t('assets:new.error.toast'));
    },
  });

  const createLoan = useCreateLoan({
    onError: (err) => {
      console.error(err);
      toast.error(t('assets:new.error.toast'));
    },
  });

  const defaultAsset:Partial<OtherAsset> = {
    acquisitionDate: new Date(),
    evolutionRate: 0,
    sharePercentage: 1,
  }

  return (
    <>
      <Title uppercase title={t('assets:other.create.page.title')} level={3} />
      <OtherAssetInputForm
        asset={defaultAsset}
        onSubmit={async ({loans, ...asset}) => {
          const res = await createOtherAsset(asset);
          if(!res.data || !res.data.createOtherAsset.data) {
            toast.error(t('assets:new.error.toast'));
          } else {
            const newAsset = res.data.createOtherAsset.data;
            if(loans) {
              await Promise.all(loans.map(({id, ...loan}) =>
                createLoan({
                  ...loan,
                  assetId: newAsset.id
                })
              ));
            }
            toast.success(t('assets:new.success.toast'));
            history.push('/');
          }
        }}
      />
    </>
  )
}
