import {FunctionComponent, useEffect, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import {Sheet, Title, Fieldset, InputRow, Button} from "@scriba/ui-lib";
import {recurrentPercentageEvolution, getMinAvgMax, monthsBetween, StockIndexEnum, StocksMAMType, stockIndexList} from "@scriba/common";

import {
  ScenarioDetailsFragmentFragment,
  BuyStocksScenarioFragmentFragment,
  GetObjectiveQuery,
  GetObjectiveQueryVariables,
  GetScenarioQuery,
  GetScenarioQueryVariables,
  UpdateScenarioMutation,
  UpdateScenarioMutationVariables,
} from "../generated/graphql";
import {ObjectiveOverviewBar} from "../components/ObjectiveOverviewBar";
import scenarios from "../queries/scenarios";
import objectives from "../queries/objective";
import FieldsAndButton from "../components/FieldsAndButton";
import MonetaryAmountInputField from "../components/fields/MonetaryAmountInputField";
import PercentageInputField from "../components/fields/PercentageInputField";
import SelectInputField from "../components/fields/SelectInputField";
import {toast} from "react-toastify";
import Group from "../components/Group";
import {HighlightedTextBorderCentered} from "../components/HighlightedTextBorder";
import { formatMonetaryAmount, formatPercentage } from "../services/format";
import styled from "styled-components";
import {Column} from "../components/Column";
import HighlightedText from "../components/HighlightedText";

import {useStocks} from "../providers/StocksProvider";

type BuyStocksInputs = {
  amount: number
  investedAmount: number
  rentabilityChoice: number
  stockIndex: string 
}

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ObjectiveScenariosBuyStocks: FunctionComponent = () => {
  const {t} = useTranslation(['objectives', 'form']);
  const history = useHistory();
  let { scenarioId, objectiveId } = useParams<{scenarioId: string, objectiveId: string}>();
  const { data } = useQuery<GetScenarioQuery, GetScenarioQueryVariables>(scenarios.get, {variables: { id: scenarioId }});
  const { data: objectiveData } = useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(objectives.get, {variables: { id: objectiveId }});
  const formMethods = useForm<BuyStocksInputs>({
    defaultValues: {
      investedAmount: 0,
    }
  });
  const [updateScenario] = useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(
    scenarios.update,
    {
      onError: (error) => {
        console.error(error)
        toast.error(t('objectives:scenario.update.error.toast'));
      },
    }
  );
  // créer un hook / context
  const {loading : stockLoading,  stocks } = useStocks();

  const watchAmount = formMethods.watch('investedAmount');
  const watchRentabilityChoice: number = formMethods.watch('rentabilityChoice');
  const watchStockIndex: string = formMethods.watch('stockIndex');

  const {setValue} = formMethods;
  useEffect(() => {
    if (!data?.scenario) return; // loading
    if (!objectiveData?.objective) return; // loading
    const scen = data.scenario as ScenarioDetailsFragmentFragment & BuyStocksScenarioFragmentFragment;
    setValue("investedAmount", scen.investedAmount || objectiveData.objective.futureAvailableCash || 0);
    setValue("rentabilityChoice", scen.rentabilityChoice || 0);
    if (scen.stockIndex !== undefined || scen.stockIndex !== null)
      setValue("stockIndex", scen.stockIndex);
  }, [setValue, data?.scenario, objectiveData?.objective]);

  const objective = objectiveData?.objective;

  // todo
  const objectiveDelay = objective ? monthsBetween(new Date(), objective.objectiveDate) : 0;
  const [resStocks, setResStocks] = useState<StocksMAMType | null>(null);
  useEffect(() => {
    setResStocks(stockLoading && stocks !== undefined ? getMinAvgMax(stocks, objectiveDelay) : null);
  }, [stockLoading, stocks, objectiveDelay]);

  if (!data?.scenario || !objective) return null;

  const resultValue = watchAmount !== null && watchAmount !== undefined &&
    watchRentabilityChoice !== null && watchRentabilityChoice !== undefined ?
    watchAmount*recurrentPercentageEvolution(objectiveDelay/12, watchRentabilityChoice) : 0;

  const col = (value: number | undefined, percentage: boolean) => (
      <Column>
        <HighlightedText textAlign={'center'}>
          {!value ? 'n/a' :
            percentage ?
              formatPercentage(value/100, 2) :
              formatMonetaryAmount(value, objective.currency.code)
          }
        </HighlightedText>
      </Column>
  );
  const minAvgMax = (min: number | undefined, avg: number | undefined, max: number | undefined, percentage: boolean) => (
    <FlexDiv>
      {col(min, percentage)}
      {col(avg, percentage)}
      {col(max, percentage)}
    </FlexDiv>
  )

  console.log("stocks", stocks);

  const indexValue = !!watchStockIndex && resStocks !== null ? resStocks[watchStockIndex as StockIndexEnum] : null;

  const rentMin = indexValue?.min;
  const rentAvg = indexValue?.avg;
  const rentMax = indexValue?.max;
  const annualisedRentAbs = (rent: number) => (Math.pow(1+Math.abs(rent)/100, 12/objectiveDelay)-1)*100;
  const annualisedRent = (rent: number | undefined) => rent !== undefined ? (rent < 0 ? -annualisedRentAbs(rent) : annualisedRentAbs(rent)) : undefined;
  const rentMinAnnualised = annualisedRent(rentMin);
  const rentAvgAnnualised = annualisedRent(rentAvg);
  const rentMaxAnnualised = annualisedRent(rentMax); 

  console.log("Stocks annual/<N> min", rentMinAnnualised, rentMin)
  console.log("Stocks annual/<N> avg", rentAvgAnnualised, rentAvg)
  console.log("Stocks annual/<N> max", rentMaxAnnualised, rentMax)

  const gainMin = rentMin ? (rentMin * watchAmount / 100) : undefined;
  const gainAvg = rentAvg ? (rentAvg * watchAmount / 100) : undefined;
  const gainMax = rentMax ? (rentMax * watchAmount / 100) : undefined;

  const gainFields = (
    <InputRow
      label={t('scenario.buyStocks.gain.label', {nbMonths: objectiveDelay})}
      tooltip={t('scenario.buyStocks.gain.tooltip', {nbMonths: objectiveDelay, investedAmount: watchAmount})}
      required={false}
      renderInput={() => minAvgMax(gainMin, gainAvg, gainMax, false)}
    />
  );
  const minAvgMaxHeaders = (
    <InputRow
      label={''}
      required={false}
      renderInput={() => 
        <FlexDiv>
          {['min', 'avg', 'max'].map(txt => 
              <Column>
                <HighlightedText textAlign={'center'}>
                  {t(`scenario.buyStocks.${txt}.label`)}
                </HighlightedText>
              </Column>
          )}
        </FlexDiv>
      }
    />
  );
  const rentabilityFields = (
    <InputRow
      label={t('scenario.buyStocks.rentability.label', {nbMonths: objectiveDelay})}
      tooltip={t('scenario.buyStocks.rentability.tooltip', {nbMonths: objectiveDelay})}
      required={false}
      renderInput={() => minAvgMax(rentMin, rentAvg, rentMax, true)}
    />
  );
  const rentabilityAnnualisedFields = (
    <InputRow
      label={t('scenario.buyStocks.annualisedRentability.label')}
      tooltip={t('scenario.buyStocks.annualisedRentability.tooltip', {nbMonths: objectiveDelay})}
      required={false}
      renderInput={() => minAvgMax(rentMinAnnualised, rentAvgAnnualised, rentMaxAnnualised, true)}
    />
  );

  const result = (
    <InputRow
      label={t('scenario.buyStocks.result.label', {nbMonths: objectiveDelay})}
      tooltip={t('scenario.buyStocks.result.tooltip', {amount: watchAmount, perc: watchRentabilityChoice*100})}
      required={false}
      renderInput={() => (
        <div>
            <HighlightedTextBorderCentered>
              {formatMonetaryAmount(resultValue, objective.currency.code)}
            </HighlightedTextBorderCentered>
        </div>
      )}
    />
  );

  const disclaimer = (
    <p>
      {t('scenario.buyStocks.disclaimer')}
    </p>
  );

  const fields = (
    <Fieldset>
      <MonetaryAmountInputField
        currency={objective.currency}
        label={t('scenario.buyStocks.amount.label')}
        name={"investedAmount"}
        required={true}
        formMethods={formMethods} />
      <SelectInputField
        label={t('scenario.buyStocks.stockIndexes.label')}
        tooltip={t('scenario.buyStocks.stockIndexes.tooltip', {nbMonths: objectiveDelay})}
        name="stockIndex"
        formMethods={formMethods}
        required={false}
        options={stockIndexList.map((stockIndex: StockIndexEnum) => (
          <option key={stockIndex.toString()} value={stockIndex.toString()}>{stockIndex.toString()}</option>
        ))}
      />

      {minAvgMaxHeaders}
      {rentabilityFields}
      {gainFields}
      {rentabilityAnnualisedFields}

      <PercentageInputField
        label={t('scenario.buyStocks.rentabilityChoice.label')}
        tooltip={t('scenario.buyStocks.rentabilityChoice.tooltip')}
        name="rentabilityChoice"
        formMethods={formMethods}
        required={true}
      />
      {result}
      {disclaimer}
    </Fieldset>

  )

  const bottomButton = (
    <InputRow
      label={t('common:other.requiredFields')}
      required={false}
      renderInput={() => (
        <Button type="submit" label={t('form:button.save.label')}/>
      )}
    />
  );
  return (
    <Fieldset>
      <Title title={t('scenario.overview.page.title')} level={3} />
      <Sheet>
        <Fieldset>
          <ObjectiveOverviewBar objective={objective}  />
          <Group>
            <form onSubmit={formMethods.handleSubmit(async(values) => {
              await updateScenario({variables: {input: {id: scenarioId,
                    investedAmount: values.investedAmount,
                    amount: resultValue,
                    stockIndex: values.stockIndex,
                    rentabilityChoice: values.rentabilityChoice
                  }}});
              history.push(`/objectives/${objectiveId}/scenarios`);
            })}>
              <FieldsAndButton fields={fields} button={bottomButton}/>
            </form>
          </Group>
        </Fieldset>
      </Sheet>
    </Fieldset>
  );
}
