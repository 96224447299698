import React, {FunctionComponent} from "react";
import {useQuery} from "@apollo/client";
import { toast } from "react-toastify";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import styled from "styled-components";

import {netValue} from "@scriba/common";
import {Table, TBody, Td, Th, THead, Tr, TableFooter, Text, Button} from "@scriba/ui-lib";

import assets from "../queries/assets";
import ButtonLink from "./navigation/ButtonLink";
import {formatDate, formatMonetaryAmount} from "../services/format";
import {useRemoveOtherAsset} from "../queries/assets/others";
import {useConfirmation} from "../providers/ConfirmDialogProvider";
import {ListAssetsQuery, OtherAssetDetailsFragmentFragment} from "../generated/graphql";
import {HelpTooltip} from "@scriba/ui-lib/dist/components/HelpTooltip";

const RightAlignTd = styled(Td)`
  text-align: right;
`

const RightAlignTh = styled(Th)`
  text-align: right;
`
const AssetNetValue: FunctionComponent<{asset: OtherAssetDetailsFragmentFragment, date?: Date}> = ({asset, date = new Date()}) => {
  return (
    <>
      {formatMonetaryAmount(netValue(asset, date), asset.currency.code, 0)}
    </>
  )
}

export const AssetsList: FunctionComponent = () => {
  const {t} = useTranslation(['assets', 'common']);
  const history = useHistory();
  const { data } = useQuery<ListAssetsQuery>(assets.list, {fetchPolicy: "cache-and-network"});
  const confirm = useConfirmation();
  const removeOtherAsset = useRemoveOtherAsset ({
      onError: () => {
        toast.error(t('assets:remove.error.toast'));
      },
      onCompleted: () => {
        toast.success(t('assets:remove.success.toast'));
      }
    }
  )

  return (
    <>
      {!!data && (
        <Table style={{tableLayout: "fixed"}}>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col style={{width: "150px"}}/>
            <col style={{width: "100px"}}/>
          </colgroup>
          <THead>
            <Tr>
              <Th>{t('assets:table.header.name')}</Th>
              <Th>{t('assets:table.header.subType')}</Th>
              <Th>{t('assets:table.header.acquisitionDate')}</Th>
              <RightAlignTh>{t('assets:table.header.netValue')}&nbsp;<HelpTooltip text={t('assets:table.header.netValue.tooltip')} /></RightAlignTh>
              <RightAlignTh>{t('assets:table.header.netValue.10years')}&nbsp;<HelpTooltip text={t('assets:table.header.netValue.10years.tooltip')} /></RightAlignTh>
              <RightAlignTh>{t('assets:table.header.netValue.20years')}</RightAlignTh>
              <Th colSpan={2}/>
            </Tr>
          </THead>
          <TBody>
            {data.assets.map(asset => (
              <Tr key={asset.id}>
                <Td>{asset.name}</Td>
                <Td>{t(`assets:other.subType.${asset.subType}`)}</Td>
                <Td>{formatDate(asset.acquisitionDate)}</Td>
                <RightAlignTd><AssetNetValue asset={asset} /></RightAlignTd>
                <RightAlignTd><AssetNetValue asset={asset} date={dayjs().add(10, 'years').toDate()} /></RightAlignTd>
                <RightAlignTd><AssetNetValue asset={asset} date={dayjs().add(20, 'years').toDate()} /></RightAlignTd>
                <Td><ButtonLink appearance='transparent' label={t('common:button.modify.label')} to={`/assets/other/${asset.id}/edit`} /></Td>
                <Td><Button appearance='transparent' iconName='delete' color='main' onClick={async() => {
                  confirm({
                    title: t('assets:remove.confirmation.title', {assetName: asset.name}),
                    content: t('assets:remove.confirmation.content')
                  }).then(() =>
                    removeOtherAsset(asset.id)
                  );
                }}/></Td>
              </Tr>
            ))}
            <Tr>
              <td colSpan={8} onClick={() => history.push('/assets/other/new')}>
                <TableFooter>
                  <Button
                    iconName='add'
                    color='secondary'
                  />
                  <Text color='neutral'>{t('assets:new.other.button.label')}</Text>
                </TableFooter>
              </td>
            </Tr>
          </TBody>
        </Table>
      )}
    </>
  );
}
