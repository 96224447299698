import React, {FunctionComponent} from 'react';
import {useMutation} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Movement, MovementPeriod} from "@scriba/common";

import MovementInputForm from "../components/forms/MovementInputForm";
import movements, {fragments} from "../queries/movements";
import {Title} from '@scriba/ui-lib';
import {CreateMovementMutation, CreateMovementMutationVariables} from "../generated/graphql";

export const CreateMovementPage: FunctionComponent = () => {
  const {t} = useTranslation(['movements', 'common']);
  const history = useHistory();
  const [createMovement] = useMutation<CreateMovementMutation, CreateMovementMutationVariables>(
    movements.create,
    {
      update: async (cache, res) => {
        cache.modify({
          fields: {
            movements(existingMovementRefs = [], { readField }) {
              const newMovementRef = cache.writeFragment({
                data: res.data!.createMovement.data,
                fragment: fragments.details,
              });
              return [...existingMovementRefs, newMovementRef];
            }
          }
        });
      },
    }
  )

  const defaultMovement:Partial<Movement> = {
    startDate: new Date(),
    period: MovementPeriod.once,
  }

  return (
    <>
      <Title uppercase title={t('movements:other.create.page.title')} level={3} />
      <MovementInputForm
        movement={defaultMovement}
        onSubmit={async (input) => {
          const {asset, ...remainder} = input
          await createMovement({
            variables: {
              input: {
                ...remainder,
                currencyCode: 'EUR', //TODO
                assetId: asset.id
              }
            }
          });
          history.push('/');
        }}
      />
    </>
  )
}
