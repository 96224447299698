import {FunctionComponent} from "react";
import styled from "styled-components";

import {getGridSize} from "@scriba/ui-lib";

import SideBar from "./SideBar";
import NavigationBar from "./NavigationBar";
import {space} from "@scriba/ui-lib/dist/themes/accessors";

const RightContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: ${getGridSize(20)};
`

const MainContainer = styled('div')`
  flex-grow: 1;
  margin: ${space('xxl')}px;
`

interface NavigationStructureProps {
  pageTitle?: string,
  withBackButton?: boolean,
}

const NavigationStructure: FunctionComponent<NavigationStructureProps> = ({pageTitle, withBackButton, children }) => {
  return (
    <>
      <SideBar/>
      <RightContainer>
        <NavigationBar pageTitle={pageTitle} withBackButton={withBackButton}/>
        <MainContainer>
          {children}
        </MainContainer>
      </RightContainer>
    </>
  )
}

export default NavigationStructure;
