import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {Button, Card, Fieldset, Title} from "@scriba/ui-lib";

import {objectiveIconName} from "../services/objectives";
import {ObjectiveDetailsFragmentFragment} from "../generated/graphql";
import { formatMonetaryAmount } from "../services/format";

type MyObjectiveCardProps = {
  objective: ObjectiveDetailsFragmentFragment;
}

const MyObjectiveCard = ({objective}: MyObjectiveCardProps) => {
  const {t} = useTranslation('objectives');
  const history = useHistory();

  if (! objective.subType) return null;
  return (
    <Card
      onClick={() => history.push(`/objectives/${objective.id}/scenarios`)}
      footer={<Button label={t('objective.card.edit.label')} />}
      iconName={objectiveIconName({subType: objective.subType})}
    >
      <Fieldset>
        <div>
          <Title title={t(`objective.card.title.${objective.subType}`)} level={6}/>
          <p/>
        </div>

        <div>
          <Title color="main" level={1} title={objective.name}/>
          <Title title={formatMonetaryAmount(objective.amount, objective.currency.code)} level={4}/>
        </div>
      </Fieldset>    
    </Card>
  )
}

export default MyObjectiveCard;
