import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {ApolloProvider} from "@apollo/client";

import {DarkTheme} from "@scriba/ui-lib";

import './i18n';
import Main from './components/Main';
import reportWebVitals from './reportWebVitals';
import {apolloClient} from "./config";
import {AuthProvider} from "./providers/AuthProvider";
import RouteLogger from "./components/RouteLogger";
import {ConfirmationServiceProvider} from "./providers/ConfirmDialogProvider";
import { StocksProvider } from './providers/StocksProvider';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={"loading..."}>
      <Router>
        <ThemeProvider theme={DarkTheme}>
          <ApolloProvider client={apolloClient}>
            <ConfirmationServiceProvider>
              <AuthProvider>
                <StocksProvider>
                  <RouteLogger />
                  <Main />
                </StocksProvider>
              </AuthProvider>
            </ConfirmationServiceProvider>
          </ApolloProvider>
        </ThemeProvider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
